.App {
	text-align: center;
}

body {
	background-color: #efefef;
	color: black !important;
}

.App-logo {
	height: 20vmin;
	pointer-events: none;
}

.air-mi-logo {
	height: 200px;
}

.page-body {
	width: 100%;
	margin-left: 75px;
}

.home-page {
	width: 100%;
}

.nav-bar {
	height: 100vh;
	min-width: 75px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
}

.nav-initial {
	height: 45px;
	width: 45px;
	border-radius: 50%;
	background-color: #757575;
	text-align: center;
	vertical-align: middle;
	line-height: 45px;
}

.nav-selected-icon {
	height: 45px;
	width: 45px;
	border-radius: 50%;
	background-color: #75757579;
	text-align: center;
	vertical-align: middle;
	line-height: 20px;
}

.nav-unselected-icon {
	height: 45px;
	width: 45px;
	border-radius: 50%;
	background-color: transparent;
	text-align: center;
	vertical-align: middle;
	line-height: 20px;
}

.hero-image {
	background-image: url(../src/Assets/image001.png);
	height: 200px;
	width: 100%;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		align-items: center;
		animation: App-logo-spin infinite 12s linear;
	}
}

.App-header {
	background-color: #000000;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.full {
	background-color: #efefef;
}
.Whole {
	background-color: #efefef;
}
.button {
	transition-duration: 0.3s;
	color: white;
	padding: 20px 30px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 15px;
	margin: 40px 100px 40px 100px;
	cursor: pointer;
}

a {
	text-decoration: none;
}

.button1 {
	background-color: #000000;
	border: 2px solid #ffffff;
	border-radius: 15px;
	font-weight: 900;
	font-size: 30px;
	font-family: "Varela Round", sans-serif;
	margin: 0 auto;
	display: block;
	text-align: center;
}

.button1:hover {
	background-color: #ceff00;
	border: 2px solid #000000;
	color: #000000;
}

.iris-logo-text {
	text-align: center;
	color: black;
}

.iris-app-logo {
	height: 20vmin;
	pointer-events: none;
	display: block;
	margin: 0 auto;
	margin-bottom: 50px;
}

/* FROM DCS PAGE */

.dcs-box {
	width: 350px;
	height: 250px;
	background-color: red;
	border-radius: 15px;
	transition-duration: 0.3s;
}
.fabric-box {
	width: 250px;
	height: 150px;
	background-color: red;
	border-radius: 15px;
	transition-duration: 0.3s;
}
.cl-text{
	font-size: 18px;
    line-height: 24px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: 400;
	margin-top:8px;
}
.rightslab {
	height: calc(100% - 100px);
	width: calc(50% - 5%);
	position: fixed;
	top: -10px;
	padding-top: 50px;
	padding-bottom: 100px;
	right: 0;
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding-left: 20%;
}

.dcs-form {
	margin-left: 10%;
	float: left;
}

.dcs-button-end {
	margin-left: 80%;
	float: left;
}

/* FROM LAB PAGE */

.lab-page-flex {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: transparent;
}

.lab-top-slab {
	background-color: transparent;
	padding-top: 20px;
	padding-bottom: 20px;
}

.lab-bottom-slab {
	background-color: transparent;
	overflow-y: scroll;
	overflow-x: scroll;
}

.lab-form {
	width: 70%;
	padding-bottom: 15px;
	padding-top: 15px;
	margin: 0 auto;
}

.lab-dropdowns {
	width: 90%;
	display: flex;
	justify-content: space-between;
	padding-right: 10px;
	margin-bottom: 35px;
}

.lab-color-cell {
	width: 50px;
	height: 20px;
	border-radius: 7px;
	border: 1px solid black;
}

.lab-color-box {
	height: 80px;
	width: 80px;
	display: inline-block;
	border-radius: 10px;
	border: 1px solid black;
}

.toolbar {
	z-index: 2;
	width: 250px;
	background-color: black;
	border-radius: 0px 0px 0px 15px;
	height: 50px;
	position: fixed;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	top: 0px;
	right: 0px;
	margin-left: 40px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	border: 1px solid white;
	border-width: 0px 0px 1px 1px;
	transition-duration: 0.3s;
}

.toolbar-folded-up {
	top: -60px;
	transition-duration: 0.3s;
}

.toolbar-home {
	font-size: 40px;
	margin-top: 5px;
	color: white;
	transition-duration: 0.3s;
}

.toolbar-home:hover {
	color: #ceff00;
	transition-duration: 0.3s;
}

.toolbar-spacer {
	width: 30px;
}

.toolbar-userbox {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.toolbar-userbox-icon {
	font-size: 30px;
	margin: auto;
}

.toolbar-right-group {
	display: flex;
	width: 100px;
}

.toolbar-fold {
	position: absolute;
	font-size: 25px;
	left: 130px;
	top: 80px;
	opacity: 0.3;
	transition-duration: 0.3s;
	width: 25px;
	height: 25px;
	background-color: black;
	border-radius: 14px;
	line-height: 25px;
	padding-left: 0px;
	border: 2px solid black;
	transform: rotate(0deg);
}

.toolbar-fold:hover {
	opacity: 1;
	transition-duration: 0.3s;
	transform: rotate(180deg);
}

.toolbar-fold-rotated {
	position: absolute;
	font-size: 25px;
	left: 130px;
	top: 80px;
	opacity: 0.3;
	transition-duration: 0.3s;
	width: 25px;
	height: 25px;
	background-color: black;
	border-radius: 14px;
	line-height: 25px;
	padding-left: 0px;
	border: 2px solid black;
	transform: rotate(180deg);
}

.toolbar-fold-rotated:hover {
	opacity: 1;
	transition-duration: 0.3s;
	transform: rotate(0deg);
}

.dye-boxes {
	width: 100%;
	display: flex;
	justify-content: space-around;
}

.dye-box {
	width: 100%;
	margin: 2px;
	padding: 10px;
	border-radius: 5px;
	position: relative;
	transition-duration: 0.3s;
	transition-timing-function: ease;
	margin-right: 10px;
	margin-left: 10px;
}

.half-trans {
	opacity: 0.5;
}

.dye-box-flavor {
	background-color: black;
	position: absolute;
	color: white;
	right: 1.2vw;
	top: -10px;
	padding-left: 5px;
	padding-right: 5px;
	font-size: 20px;
}

.dye-menu-button {
	width: 100%;
	height: 30px;
	z-index: 1;
}

.menu-downarrow {
	opacity: 0.5;
	position: absolute;
	top: 5px;
}

.input-dye-boxes {
	height: 80px;
	display: flex;
	justify-content: space-around;
	width: 100%;
	padding-top: 30px;
}

.input-dye-box {
	margin: 2px;
	padding: 10px;
	margin-right: 10px;
	margin-left: 10px;
	width: 30vw;
}
.input-color-box {
	margin: 2px;
	padding: 10px;
	margin-right: 10px;
	margin-left: 10px;
	width: 25vw;
}

.dyes-submit-button {
	background-color: #000000;
	border: 1px solid #ffffff;
	border-radius: 5px;
	font-weight: 900;
	font-size: 20px;
	font-family: "Varela Round", sans-serif;
	margin: 0 auto;
	display: block;
	transition-duration: 0.3s;
	color: white;
	padding: 15px 25px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	margin-left: 15px;
	margin-top: 25px;
}

.dyes-submit-button-disabled {
	background-color: #000000;
	border: 1px solid #ffffff;
	border-radius: 5px;
	font-weight: 900;
	font-size: 20px;
	font-family: "Varela Round", sans-serif;
	margin: 0 auto;
	display: block;
	transition-duration: 0.25s;
	color: white;
	padding: 15px 25px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	cursor: pointer;
	margin-left: 15px;
	margin-top: 25px;
	pointer-events: none;
	opacity: 0.3;
}

.dyes-submit-button:hover {
	background-color: #ceff00;
	border: 1px solid #000000;
	color: #000000;
}

.rgb-box-result-text {
	font-size: 30px;
	margin-bottom: -20px;
	font-family: "Varela Round", sans-serif;
	position: absolute;
	background-color: black;
	top: -20px;
}

.rgb-box-container {
	height: 150px;
	width: 300px;
	border: 1px solid white;
	border-radius: 5px;
	padding: 15px;
	display: flex;
	flex-direction: column;
	position: relative;
	float: left;
	margin-top: 25px;
	margin-left: 10px;
}

.rgb-box {
	margin-top: 10px;
	margin-bottom: 10px;
	height: 100%;
	width: 100%;
	background-color: red;
	border-radius: 10px;
}

.page-title {
	font-size: 60px;
	opacity: 0.9;
	font-family: "Varela Round", sans-serif;
	margin-bottom: 10px;
}

.page-info {
	font-family: "Varela Round", sans-serif;
	opacity: 0.9;
	margin-left: 20px;
	font-size: 18px;
	line-height: 25px;
}

.error-text {
	color: #ff5964;
	margin-left: 20px;
	margin-top: 20px;
	animation: fadein 0.5s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.loading-text {
	margin-left: 20px;
	margin-top: 20px;
	animation: fadein 0.5s;
}

.error-button {
	color: #ff5964;
	margin-left: 10px;
	background-color: transparent;
	border: 1px solid #ff5964;
	font-size: 15px;
	border-radius: 5px;
	padding: 2px;
	padding-left: 8px;
	padding-right: 8px;
	transition-duration: 0.25s;
	font-weight: 900;
	text-align: center;
}

.error-button:hover {
	color: black;
	background-color: #ff5964;
	border: 1px solid #ff5964;
	font-size: 20px;
}

.upload-wrapper {
	height: 100vh;
}

.file-drop-zone {
	height: 400px;
	width: 400px;
}

.fileUploadCont {
    background-color: #fff;
    border-radius: 4px;
    border: solid #ccc;
    margin: 20px;
    padding: 30px;
    width: 550px;
}

.header {
    color: #3f4c6b;
    font-size: 18px;
    font-weight: 450;
}

.refresh-icon{
				height: 45px;
				width: 45px;
				border-radius: 50%;
				background-color: black;
				text-align: center;
				vertical-align: middle;
				line-height: 20px;
}

.download-button{
				cursor: pointer;
				margin-left: 12px;
}

.display-download{
				display: flex;
				align-items: center;
				justify-content: space-between;
	}


/* form {
    background-color: #c3d9ff8f;
    border-radius: 4px;
    display: block;
    margin: 40px auto;
    padding: 20px;
    text-align: left;
}

form button {
    display: block !important;
    margin: 20px auto;
} */
